import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { Translate, Localize, getLocale } from "react-i18nify";

import { kioskstyles } from "./../../kioskstyles";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import HttpContext from "../../contexts/HTTP/HttpContext";
import SocketIOContext from "../../contexts/Kiosk/SocketProvider/SocketIOContext";

import { useNavigate } from "react-router-dom";

import { io } from "socket.io-client";

let env = require("./../../env.js");

const APP_URL = env.REACT_SCOCKETIO_URL;

// export const socket = io(APP_URL, {
//   autoConnect: false
// });
// const socket = io(APP_URL, {
//   autoConnect: false
// });

function DebugSocketIO({ authenticate }) {
  const theme = useTheme();
  const { post, get } = useContext(HttpContext);
  const {
    isConnected,
    socketEvents,
    connect,
    disconnect,
    getSocket,
    sendEvent
  } = useContext(SocketIOContext);

  const [result, setResult] = useState("");
  const [errorResult, setErrorResult] = useState("");

  const [testfunc, setTestfunc] = useState();

  const navigate = useNavigate();

  const [orientation, setOrientation] = useState(
    window.screen.orientation.type
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.screen.orientation.type);
    };

    window.screen.orientation.addEventListener(
      "change",
      handleOrientationChange
    );

    return () => {
      window.screen.orientation.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);

  useEffect(() => {
    console.log("context attach listeners");
    setTestfunc(() => testFuncImplimentation);
    return () => {
      console.log("context detach listeners");
    };
  }, []);

  useEffect(() => {
    console.log("I think an event happened!", socketEvents);
  }, [socketEvents]);

  // useEffect(() => {
  //   console.log("Debug For Context", ContextDebug);
  // }, [ContextDebug]);

  const returnToConfig = async () => {
    navigate("/configuration");
  };

  const clearGUI = () => {
    setResult("");
    setErrorResult("");
  };

  const mockEvent = () => {
    let payload = {
      type: "LOGIN",
      subscriptionID: "UP8GG",
      kioskID: "TEST_kioskID"
    };

    sendEvent(payload, onEventSent);
    //console.log(sendEvent(payload));
  };

  const onEventSent = () => {
    console.log("onEventSent");
  };

  const setReceiveEventCallback = payload => {
    console.log("setReceiveEventCallback", payload);
    setResult(payload);
  };

  const testFuncImplimentation = () => {
    console.log("testFuncImplimentation", testfunc);
  };

  const customSocketSend = () => {
    let socket = getSocket();
    let payload = {
      type: "LOGIN",
      subscriptionID: "UP8GG",
      kioskID: "TEST_kioskID"
    };

    socket.emit("event", payload, () => {
      //console.log("customSocketSend SENT SUCCESSFULLLY!");
      setResult("customSocketSend SENT SUCCESSFULLLY!");
    });
  };

  // START Debug Functions

  // const connect = async () => {
  //   try {
  //     clearGUI();
  //     socket.connect();
  //     setResult("func done");
  //   } catch (err) {
  //     setErrorResult(err);
  //   }
  // };

  // const disconnect = async () => {
  //   try {
  //     clearGUI();
  //     socket.disconnect();
  //     setResult("func done");
  //   } catch (err) {
  //     setErrorResult(err);
  //   }
  // };

  // const sendEvent = async () => {
  //   clearGUI();
  //   setErrorResult("Not Implimented");
  // };

  // END Debug Functions

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <div
        style={{
          marginLeft: "3vw",
          marginRight: "3vw",
          marginTop: "2vh",
          width: "100%"
        }}
      >
        <div style={{ marginBottom: "12px" }}>
          <Button
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={connect}
          >
            Connect
          </Button>
          <Button
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={disconnect}
          >
            Disconnect
          </Button>
          <Button
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={mockEvent}
          >
            Event via context
          </Button>
          <Button
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={testfunc}
          >
            Tets testfunc
          </Button>

          <Button
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={customSocketSend}
          >
            Test Custom
          </Button>
        </div>
        <br />
        <Typography>Connect to: {APP_URL}</Typography>
        <br />
        <Typography>Is Connected: {isConnected.toString()}</Typography>
        <br />
        <Typography>Result:</Typography>
        <RoundedDisplay text={result} />
        <br />
        <Typography>Error:</Typography>
        <RoundedDisplay text={errorResult} />

        <br />

        {/* <ul>
          {fooEvents.map((event, index) => (
            <li key={index}>{event}</li>
          ))}
        </ul> */}

        <br />
        <Button
          variant="contained"
          style={{ textTransform: "capitalize" }}
          onClick={returnToConfig}
        >
          <Translate value="application.printerdebug.returntoconfigure" />
        </Button>
      </div>
    </Grid>
  );
}

function RoundedDisplay(props) {
  if (props.text == "") {
    return <></>;
  }

  return (
    <div
      style={{
        backgroundColor: "lightgrey",
        margin: "0px",
        padding: "10px",
        borderRadius: "12px"
      }}
    >
      <Typography>{props.text}</Typography>
    </div>
  );
}

export default DebugSocketIO;

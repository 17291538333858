import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Translate, Localize, translate } from "react-i18nify";
import QRCode from "react-qr-code";

import { kioskstyles } from "./../../kioskstyles";

import "react-phone-number-input/style.css";

import { Button, Grid, Typography, IconButton, TextField } from "@mui/material";

import Logo from "./Components/Logo";
import KioskStepper from "./Components/Stepper/KioskStepper";
import PhoneKeypad from "./Components/CustomerPhoneNumber/PhoneKeypad";
import PhoneConfirm from "./Components/CustomerPhoneNumber/PhoneConfirm";
import IdleTimerDisplay from "./Components/Shared/IdleTimerDisplay";
import SubtitleWrapper from "./Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./Components/Shared/PanelElements/FlexRightPanel";
import PhoneDisplay from "./Components/CustomerPhoneNumber/PhoneDisplay";
import BackspaceButton from "./Components/CustomerPhoneNumber/BackspaceButton";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";
import SocketIOContext from "../../contexts/Kiosk/SocketProvider/SocketIOContext";

import { useNavigate } from "react-router-dom";

import { setTranslations, setLocale, getLocale } from "react-i18nify";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

import HorizontalLayout from "./Components/CustomerPhoneNumber/HorizontalLayout";
import VerticalLayout from "./Components/CustomerPhoneNumber/VerticalLayout";

let env = require("./../../env");

function displayError(errorCode) {
  switch (errorCode) {
    case 400:
      return translate("application.phonenumber.errors.unablefetchaccount");
    case 401:
      return translate("application.phonenumber.errors.invalidphone");
    case 404:
      return translate("application.errors.invalidcredentials");
    case 503:
      return translate("application.errors.serverunavailable");
    default:
      return translate("application.errors.unknownerror");
  }
}

function CustomerPhoneNumber({ authenticate }) {
  const theme = useTheme();
  const { simplepost } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    sessionID,
    setAll,
    customerReset
  } = useContext(KioskContext);

  const {
    isConnected,
    socketEvents,
    qrCode,
    qrCodeExpireTime,
    syncTimeOffset,
    connect,
    disconnect,
    getSocket,
    sendEvent,
    sendQRCodeCheck,
    clearSocketEvents
  } = useContext(SocketIOContext);

  const [phonenumber, setPhonenumber] = useState("");
  const [showconfirmdialog, setShowconfirmdialog] = useState(false);

  const [interval, setInterval] = useState(null);

  const navigate = useNavigate();

  const [orientation, setOrientation] = useState(
    window.screen.orientation.type
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.screen.orientation.type);
    };

    window.screen.orientation.addEventListener(
      "change",
      handleOrientationChange
    );

    return () => {
      window.screen.orientation.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);

  //Logic for response from Socket.IO //Just for the horizontal view
  useEffect(() => {
    Dlog("I think an event happened! (ACustomerPhoneNumber)", socketEvents);
    if (socketEvents == null) {
      console.log("socketEvents is null, ignoring");
      return;
    }

    let socketdata = socketEvents.data;
    Dlog("socketdata", socketdata);

    let action = socketdata.action;

    switch (action) {
      case "CAAPP_LOGIN":
        let newSessionID = generateSessionID();

        Dlog(
          "Got this data",
          socketdata.data.firstName,
          socketdata.data.lastName,
          socketdata.data.uniqueID
        );

        setAll(
          socketdata.data.firstName, //customerFirstName
          socketdata.data.lastName, //customerLastName
          socketdata.data.uniqueID, //customerID
          null, //numBags
          null, //numLabels
          null, //labelsList
          newSessionID //sessionID
        );

        navigate("/numbags"); //skip phonenumber input
        clearSocketEvents();
        break;
      case "RELOGIN":
        let kioskID = localStorage.getItem("kioskID");
        let subscriptionID = localStorage.getItem("subscriptionID");

        let payload = {
          type: "DATAUPDATE",
          state: "CUSTOMERPHONENUMBER",
          subscriptionID: subscriptionID,
          kioskID: kioskID
        };

        //sendEvent(payload, onEventSent);
        let socket = getSocket();

        socket.emit("event", payload);
        break;
    }
  }, [socketEvents]);

  const keypadDone = async () => {
    console.log("next called");

    //Attempt Kiosk Login
    const res = await simplepost("/kiosk/getCustomer", {
      type: "phone",
      value: phonenumber
    });

    //console.log("CPN DEBUG res.data", res.data);

    //var customerName = res.data.name;
    var customerFirstName = res.data.firstName;
    var customerLastName = res.data.lastName;
    var customerID = res.data.uniqueID;

    if (res.status >= 400) {
      setErrorMessage(displayError(res.status));
      return;
    }

    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      null, //numBags
      null, //numLabels
      null, //labelsList
      sessionID //sessionID
    );

    setErrorMessage("");
    setShowconfirmdialog(true);
  };

  const hideConfirmDialog = async () => {
    setPhonenumber("");
    setShowconfirmdialog(false);
  };

  const displaystyleForconfirmdialog = async () => {
    Dlog("displaystyleForconfirmdialog", showconfirmdialog);

    if (showconfirmdialog) {
      return { display: "none" };
    } else {
      return { display: "block" };
    }
  };

  const backspacepress = async () => {
    let newNumber = phonenumber;

    if (newNumber.length > 0) {
      newNumber = newNumber.substring(0, newNumber.length - 1);
    }

    setPhonenumber(newNumber);
  };

  const next = async () => {
    navigate("/numbags");
  };

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  const isNotMobile = useMediaQuery("(min-width:600px)");
  const isLandscape = useMediaQuery("(orientation:landscape)");

  Dlog("locale", getLocale());

  if (
    orientation == "landscape-primary" ||
    orientation == "landscape-secondary"
  ) {
    return (
      <HorizontalLayout
        //setKioskkeyFunc={setKioskkey}
        nextFunc={next}
        loading={loading}
        //errorMessage={errorMessage}
        isNotMobile={isNotMobile}
        //kioskkey={kioskkey}
      />
    );
  } else {
    return (
      <VerticalLayout
        //setKioskkeyFunc={setKioskkey}
        nextFunc={next}
        loading={loading}
        //errorMessage={errorMessage}
        isNotMobile={isNotMobile}
        //kioskkey={kioskkey}
      />
    );
  }

  return (
    <VerticalLayout
      //setKioskkeyFunc={setKioskkey}
      nextFunc={next}
      loading={loading}
      //errorMessage={errorMessage}
      isNotMobile={isNotMobile}
      //kioskkey={kioskkey}
    />

    // <IdleTimerProvider timeout={30000} onIdle={onIdle}>
    //   <Grid
    //     container
    //     rowSpacing={1}
    //     columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    //     style={kioskstyles.GridFullHeightFix}
    //   >
    //     <FlexLeftPanel isNotMobile={isNotMobile}>
    //       <div style={kioskstyles.flexLeftPanelContents}>
    //         <div style={kioskstyles.flexParentPanel}>
    //           <div style={kioskstyles.flexPanelLayoutB_TopContainer}>
    //             <Logo />
    //           </div>
    //           <div
    //             style={{
    //               ...kioskstyles.flexPanelLayoutB_MidContainer,
    //               flexDirection: "row",
    //               alignContent: "space-between",
    //               flexWrap: "wrap"
    //             }}
    //           >
    //             <div
    //               class="fontSecondaryText"
    //               style={kioskstyles.PleaseSignInElement}
    //             >
    //               <Translate value="application.phonenumber.pleasesignin" />
    //             </div>
    //             <div>
    //               <div
    //                 style={{
    //                   ...kioskstyles.blackCornedDiv,
    //                   ...kioskstyles.blackCornedDivMarginForSubtitle
    //                 }}
    //               >
    //                 <span
    //                   class="fontBold"
    //                   style={{
    //                     color: "white",
    //                     textAlign: "center",
    //                     fontSize: "clamp(12px, 4vh, 34px)"
    //                   }}
    //                 >
    //                   <Translate value="application.phonenumber.donthaveaccount" />
    //                 </span>
    //                 <br />
    //                 <div
    //                   style={{
    //                     background: "white",
    //                     padding: ".5vh",
    //                     width: "19vh"
    //                   }}
    //                 >
    //                   <img
    //                     src="/img/caapplink.png"
    //                     // objectFit="contain"
    //                     style={{
    //                       height: "auto",
    //                       width: "auto",
    //                       maxWidth: "100%",
    //                       maxHeight: "100%"
    //                     }}
    //                     alt="QR Code to CA App"
    //                   />
    //                 </div>
    //                 <br />
    //                 <span
    //                   class="fontBold"
    //                   style={{
    //                     color: "white",
    //                     textAlign: "center",
    //                     fontSize: "clamp(9px, 3vw, 24px)"
    //                   }}
    //                 >
    //                   <Translate value="application.phonenumber.scanqr" />
    //                 </span>
    //               </div>
    //             </div>
    //             <br />

    //             <br />
    //           </div>
    //           <div
    //             style={{
    //               ...kioskstyles.flexPanelLayoutB_BotContainer,
    //               //position: "fixed",
    //               bottom: "10.5vh"
    //             }}
    //           >
    //             <KioskStepper activeStep={0} backUrl={"/language"} />
    //           </div>
    //         </div>
    //       </div>
    //     </FlexLeftPanel>
    //     <FlexRightPanel isNotMobile={isNotMobile}>
    //       <div
    //         id="rightpanelcontents"
    //         style={kioskstyles.flexRightPanelContents}
    //       >
    //         <div
    //           style={{
    //             ...kioskstyles.flexParentPanel,
    //             marginLeft: "10vh, clamp(20px, 3vw, 95px)",
    //             marginRight: "10vh, clamp(20px, 3vw, 95px)"
    //           }}
    //         >
    //           <div style={kioskstyles.flexPanelLayoutD}>
    //             <SubtitleWrapper isNotMobile={isNotMobile}>
    //               <Translate value="application.phonenumber.signinwithphone" />
    //             </SubtitleWrapper>
    //             <div
    //               style={{
    //                 width: "100%",
    //                 textAlign: "center",
    //                 fontSize: "large"
    //               }}
    //             >
    //               <Translate value="application.phonenumber.enterslowly" />
    //             </div>

    //             <div
    //               id="phonedisplay"
    //               style={{
    //                 display: "flex",
    //                 //marginTop: "10.5vh",
    //                 width: "100%",
    //                 justifyContent: "center"
    //               }}
    //             >
    //               <PhoneDisplay value={phonenumber} />
    //               <BackspaceButton
    //                 show={!showconfirmdialog}
    //                 onClick={backspacepress}
    //               />
    //             </div>

    //             <div
    //               id="PhoneBlock"
    //               style={{ width: "100%", height: "52.8vh" }} // Used to keep the model and keypad lined up
    //             >
    //               <PhoneKeypad
    //                 phonenumber={phonenumber}
    //                 setPhonenumber={setPhonenumber}
    //                 keypadDone={keypadDone}
    //                 loading={loading}
    //                 errorMessage={errorMessage}
    //                 showconfirmdialog={showconfirmdialog}
    //               />
    //               <PhoneConfirm
    //                 phonenumber={phonenumber}
    //                 showconfirmdialog={showconfirmdialog}
    //                 hideConfirmDialog={hideConfirmDialog}
    //                 next={next}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </FlexRightPanel>
    //   </Grid>
    //   <IdleTimerDisplay />
    // </IdleTimerProvider>
  );
}

//copied from ALanguageSelect
function generateSessionID() {
  let newSessionID = null;
  if (crypto.randomUUID != null) {
    newSessionID = crypto.randomUUID();
  } else {
    // in the case of http, randomUUID is not available; This case should be used on the servers, as the servers should redirect to https.
    console.log(
      "[WARNING] crypto.randomUUID doesn't exist, HTTPS is probably not enabled. Using fallback."
    );
    newSessionID = mockUniqueID(32);
  }
  return newSessionID;
}

function mockUniqueID(length) {
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export default CustomerPhoneNumber;
